import React, { useState } from "react";
import {
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { callApi, useApi } from "../services/external-api";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useAuth0 } from "../services/react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    width: "95%",
  },
  avatar: {
    backgroundColor: red[500],
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
}));

const MissingPreviewImage = () => {
  const { getTokenSilently } = useAuth0();
  const classes = useStyles();
  const [galleries, isLoading, , refetch] = useApi(
    "/galleries/with-missing-preview",
    []
  );
  const [showMedia, setShowMedia] = useState(null);
  const [forcePersonalItems, setForcePersonalItems] = useState();

  if (isLoading) {
    return <CircularProgress />;
  }

  const forceCleanPersonalItems = async (id) => {
    const token = await getTokenSilently();
    await callApi(`/galleries/${id}/force-clean`, token, "GET");
    await refetch();
  };

  if (forcePersonalItems) {
    forceCleanPersonalItems(forcePersonalItems);
    setForcePersonalItems(undefined);
  }

  const displayModal = (image) => {
    return (
      <Dialog
        onClose={() => setShowMedia(null)}
        open={true}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <img src={image} alt="preview" />
        </DialogContent>
      </Dialog>
    );
  };

  const renderGallery = (gallery) => {
    const original = gallery.original_geojson.features.length;
    const cleaned = gallery.cleaned_geojson.features.length;
    return (
      <Grid item xs={12} key={gallery.id}>
        <Card className={classes.card} variant={"outlined"}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {gallery.geocode_raw.display_name.substring(0, 1)}
              </Avatar>
            }
            title={gallery.geocode_raw.display_name}
            subheader="September 14, 2016"
          />
          {gallery.cleaned_preview_image && (
            <CardMedia
              className={classes.media}
              image={gallery.cleaned_preview_image}
            />
          )}
          {!gallery.cleaned_preview_image && (
            <CardContent>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/preview-image/${gallery.id}`}
              >
                Create Preview
              </Button>
            </CardContent>
          )}
          <CardContent>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowMedia(gallery.preview_image)}
            >
              Show Original Image
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setForcePersonalItems(gallery.id)}
            >
              Force Remove Personal Items
            </Button>
            {gallery.cleaned_preview_image && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowMedia(gallery.cleaned_preview_image)}
              >
                Show Cleaned Image
              </Button>
            )}
            <Typography variant="h6">{`ID: ${gallery.id}`}</Typography>
            <Typography variant="h6">Icons</Typography>
            <Typography variant="body1" gutterBottom>
              {`Icons initially: ${original}`}
              <br />
              {`Icons after cleaning personal items: ${cleaned}`}
              <br />
              {`Removed icons: ${original - cleaned}`}
            </Typography>
            <Typography variant="h6">Geocoding</Typography>
            <SyntaxHighlighter language="javascript" style={docco}>
              {JSON.stringify(gallery.geocode_raw, null, 2)}
            </SyntaxHighlighter>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  if (galleries.length === 0) {
    return (
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">
              All galleries have an up to date preview
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      {showMedia && displayModal(showMedia)}
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Last 5 galleries without preview image
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {galleries.map(renderGallery)}
        </Grid>
      </Container>
    </>
  );
};

export default MissingPreviewImage;
