import React from "react";
import { Router, Switch, Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import Search from "@material-ui/icons/Search";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PrivateRoute from "./components/private-route";
import Dashboard from "./pages/dashboard";
import PreviewImage from "./pages/preview-image";
import SearchPage from "./pages/search";
import history from "./utils/history";
import { useAuth0 } from "./services/react-auth0-spa";
import "./App.css";
import MissingPreviewImage from "./pages/missing-preview-image";
import CssBaseline from "@material-ui/core/CssBaseline";
import Prices from "./pages/prices";
import EditGallery from "./pages/edit-gallery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerPaper: {
    width: 240,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const ListItemLink = (props) => {
  const { icon, primary, to } = props;
  const location = useLocation();
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={to === location.pathname}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

function App() {
  const classes = useStyles();
  const { loading } = useAuth0();

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Router history={history}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Kinderkiez Galleries
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              <ListItemLink
                icon={<DashboardIcon />}
                primary="Dashboard"
                to="/"
              />
              <ListItemLink
                icon={<SettingsApplicationsIcon />}
                primary="Missing Preview"
                to="/missing-preview-image"
              />
              <ListItemLink icon={<Search />} primary="Search" to="/search" />
              <ListItemLink
                icon={<MonetizationOnIcon />}
                primary="Prices"
                to="/prices"
              />
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <Toolbar />
          <Switch>
            <PrivateRoute
              component={MissingPreviewImage}
              exact
              path="/missing-preview-image"
            />
            <PrivateRoute component={Dashboard} exact path="/" />
            <PrivateRoute component={SearchPage} exact path="/search" />
            <PrivateRoute component={PreviewImage} path="/preview-image/:id" />
            <PrivateRoute component={EditGallery} path="/galleries/:id" />
            <PrivateRoute component={Prices} path="/prices" />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
