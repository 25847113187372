import React, { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { StaticMap } from "react-map-gl";
import baseStyle from "../map/style.json";
import { styleWithDynamicLayers } from "../utils/map";
import { useApi } from "../services/external-api";
import get from "lodash/get";
import has from "lodash/has";
import Button from "@material-ui/core/Button";
import { changeDpiBlob } from "changedpi";
import { useImageLoader, loadMapImage } from "../utils/loadImage";
import { useAuth0 } from "../services/react-auth0-spa";

const style = styleWithDynamicLayers(baseStyle);
const PRINT_BLEED = 20.48;
const FORMATS = {
  SMALL: {
    width: 1024,
    height: 716.8,
    realWidth: 100,
    realHeight: 70,
    price: 99.0,
    ecommerceId: "74",
  },
  MEDIUM: {
    width: 1433.6,
    height: 1024,
    realWidth: 140,
    realHeight: 100,
    price: 159.0,
    ecommerceId: "73",
  },
  LARGE: {
    width: 2048,
    height: 1433.6,
    realWidth: 200,
    realHeight: 140,
    price: 279.0,
    ecommerceId: "39",
  },
  ROUND_MEDIUM: {
    width: 1024,
    height: 1024,
    realWidth: 100,
    realHeight: 100,
    price: 149.0,
    round: true,
    ecommerceId: "876",
  },
  ROUND_LARGE: {
    width: 1433.6,
    height: 1433.6,
    realWidth: 140,
    realHeight: 140,
    price: 179.0,
    round: true,
    ecommerceId: "878",
  },
};
// const SOURCE_LAYERS = [
//   "item-buildings",
//   "item-pois_type",
//   "item-pois_type--priority",
//   "item-pois_maki",
//   "item-pois_maki--priority",
//   "item-airports",
//   "item-airplanes",
//   "item-trainstops"
// ]
const styleReducer = (state = {}, action) => {
  const source = state.sources.placedIcons;
  const data = source.data;

  switch (action.type) {
    case "UPDATE_PLACED_ICONS":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...source.data,
              features: action.features,
            },
          },
        },
      };
    case "RESET_PLACED_ICONS":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...source.data,
              features: [],
            },
          },
        },
      };
    case "ADD_POINT":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...data,
              features: [...data.features, action.feature],
            },
          },
        },
      };
    case "REMOVE_POINT":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...data,
              features: data.features.filter(
                (f) => f.properties.id !== action.id
              ),
            },
          },
        },
      };
    case "SYNC_ACTIVE_ICON":
      return {
        ...state,
        sources: {
          ...state.sources,
          placedIcons: {
            ...source,
            data: {
              ...data,
              features: data.features.map((feature) =>
                feature.properties.id === action.id
                  ? {
                      ...action.feature,
                      properties: {
                        ...action.feature.properties,
                        priority: new Date().getTime(),
                      },
                    }
                  : feature
              ),
            },
          },
          activeIcon: {
            ...state.sources.activeIcon,
            data: action.feature,
          },
        },
      };
    case "HIDE_SOURCE_ICON_LAYERS":
      return {
        ...state,
        layers: state.layers.map((layer) =>
          get(layer, 'metadata["kinderkiez:type"]') === "MOVABLE"
            ? {
                ...layer,
                layout: {
                  ...layer.layout,
                  visibility: "none",
                },
              }
            : layer
        ),
      };
    case "SHOW_SOURCE_ICON_LAYERS":
      return {
        ...state,
        layers: state.layers.map((layer) =>
          get(layer, 'metadata["kinderkiez:type"]') === "MOVABLE"
            ? {
                ...layer,
                layout: {
                  ...layer.layout,
                  visibility: "visible",
                },
              }
            : layer
        ),
      };
    case "APPLY_OVERRIDES":
      return action.overrides ? dynamicStyle(state, action.overrides) : state;
    case "RESET":
      return action.style;
    default:
      return state;
  }
};

function dynamicStyle(
  style,
  { streetLabelSpacing, backgroundColor, hidePaths }
) {
  return {
    ...style,
    metadata: {
      ...style.metadata,
      "kinderkiez:streetLabelSpacing":
        streetLabelSpacing || style.metadata["kinderkiez:streetLabelSpacing"],
      "kinderkiez:backgroundColor":
        backgroundColor || style.metadata["kinderkiez:backgroundColor"],
      "kinderkiez:hidePaths":
        typeof hidePaths !== "undefined"
          ? hidePaths
          : style.metadata["kinderkiez:hidePaths"],
    },
    layers: style.layers.map((layer) => {
      if (
        hidePaths !== undefined &&
        layer.metadata &&
        layer.metadata["kinderkiez:type"] === "PATH"
      ) {
        layer.layout.visibility = hidePaths ? "none" : "visible";
      }

      switch (layer.id) {
        case "street-labels":
          return streetLabelSpacing
            ? {
                ...layer,
                layout: {
                  ...layer.layout,
                  "symbol-spacing": streetLabelSpacing,
                },
              }
            : layer;
        case "background":
          return backgroundColor
            ? {
                ...layer,
                paint: {
                  ...layer.paint,
                  "background-color": backgroundColor,
                },
              }
            : layer;
        default:
          return layer;
      }
    }),
  };
}

const PreviewImage = (props) => {
  const params = useParams();
  const mapRef = useRef();
  const [mapStyle, styleDispatch] = useReducer(styleReducer, style);
  const [viewport, setViewport] = useState();
  const [stored, setStored] = useState(false);
  const [storing, setStoring] = useState(false);
  const [format, setFormat] = useState("LARGE");
  const [galleries] = useApi(`/galleries/${params.id}`, []);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap();
    window.devicePixelRatio = 2.93;
    map.resize();
    // SOURCE_LAYERS.forEach(layer => {
    //   map.setLayoutProperty(layer, "visibility", "none");
    // });
  }, []);

  useImageLoader(mapRef.current);

  async function populateMap(data) {
    styleDispatch({
      type: "RESET",
      style,
    });

    styleDispatch({
      type: "HIDE_SOURCE_ICON_LAYERS",
    });

    if (mapRef.current) {
      const images = get(data, "features", []).map((feature) =>
        loadMapImage(mapRef.current.getMap(), feature.properties.icon)
      );

      await Promise.all(images);
    }

    if (has(data, "properties.overrides")) {
      styleDispatch({
        type: "APPLY_OVERRIDES",
        overrides: data.properties.overrides,
      });
    }

    if (has(data, "properties.format")) {
      setFormat(data.properties.format);
    }

    if (has(data, "properties.viewport")) {
      setViewport(data.properties.viewport);
    }

    if (mapRef.current && mapRef.current.getMap().getSource("placedIcons")) {
      mapRef.current.getMap().getSource("placedIcons").setData(data);
    }
  }

  useEffect(() => {
    populateMap(galleries.cleaned_geojson);
  }, [galleries]);

  const storeImage = async () => {
    const map = mapRef.current.getMap();
    map.resize();
    map.getCanvas().toBlob(
      async (data) => {
        setStoring(true);
        const dpiBlob = await changeDpiBlob(data, 76.2);
        const fd = new FormData();
        fd.append("preview_image", dpiBlob, "preview_image.png");

        const token = await getTokenSilently();
        await fetch(
          `${process.env.REACT_APP_GALLERY_API_URL}/galleries/${params.id}/preview-image`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: fd,
          }
        );
        setStored(true);
      },
      "image/png",
      1
    );
  };

  return (
    <div>
      <main style={{ width: "100%", height: "100%", overflow: "auto" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            storeImage();
          }}
          disabled={storing === true}
        >
          {!stored && "Save Preview"}
          {stored && "Preview Stored, go back using left navigation"}
        </Button>
        <StaticMap
          {...viewport}
          ref={mapRef}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          mapStyle={mapStyle}
          width={FORMATS[format].width + PRINT_BLEED}
          height={FORMATS[format].height + PRINT_BLEED}
          preserveDrawingBuffer
          attributionControl={false}
          reuseMap
        />
      </main>
    </div>
  );
};

export default PreviewImage;
