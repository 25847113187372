import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import throttle from "lodash/throttle";
import geocoder from "@mapbox/mapbox-sdk/services/geocoding";
import { callApi } from "../services/external-api";
import { Container, Dialog, DialogContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const autocompleteService = { current: null };

const Search = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [searchResults, setSearchResults] = React.useState([]);
  const [modalImage, setModalImage] = React.useState(null);
  // const [searching, setSearching] = React.useState(false);

  const fetch = React.useMemo(
    () =>
      throttle(async (request, callback) => {
        const result = await autocompleteService.current
          .forwardGeocode({
            mode: "mapbox.places",
            language: ["de-DE"],
            query: request.input,
          })
          .send();

        callback(
          result.body.features.map((feature) => ({
            value: feature.center,
            label: feature.place_name,
          }))
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      autocompleteService.current = geocoder({
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      });
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const search = async (geocode) => {
    // setSearching(true);
    if (!geocode) {
      setSearchResults([]);
      return;
    }
    const response = await callApi(
      `/g/search/?lon=${geocode.value[0]}&lat=${geocode.value[1]}`,
      "invalid"
    );
    setSearchResults(response);
    // setSearching(false);
  };

  const city = (location) => {
    if (location.village) return location.village;
    if (location.city) return location.city;
    if (location.town) return location.town;
    if (location.state) return location.state;
    return JSON.stringify(location);
  };

  const displayModal = (image) => {
    return (
      <Dialog
        onClose={() => setModalImage(null)}
        open={true}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <img src={image} alt="preview" />
        </DialogContent>
      </Dialog>
    );
  };

  const renderResult = (result) => {
    const {
      id,
      location: { address },
      format,
      price,
    } = result;
    return (
      <Grid item xs={4} key={result.id}>
        <Card>
          <CardMedia
            component="img"
            alt={`${address.postcode} ${city(address)}`}
            image={`${result.image}?w=512`}
            title={`${address.postcode} ${city(address)}`}
          />
          <CardContent>
            <Typography variant="h5" gutterBottom>{`${address.postcode} ${city(
              address
            )}`}</Typography>
            <Typography variant="body1">{`${parseInt(
              result.distance_earth
            )}km from ${value.label}`}</Typography>
            <Typography variant="body1">{`Format: ${format}`}</Typography>
            <Typography variant="body1">{`Price: ${price}`}</Typography>
            <Typography variant="body1">{`ID: ${id}`}</Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              href="https://app.kinderkiez.net/print"
            >
              Edit
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setModalImage(result.image)}
            >
              Preview
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      {modalImage && displayModal(modalImage)}
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              id="google-map-demo"
              // style={{width: 300}}
              getOptionLabel={(option) => option.label}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                search(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for gallery"
                  variant="outlined"
                  fullWidth
                />
              )}
              renderOption={(option) => {
                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      <span>{option.label}</span>
                      <Typography variant="body2" color="textSecondary">
                        {option.value.join(", ")}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </Grid>
        </Grid>
        {searchResults.length > 0 && (
          <Grid container spacing={3}>
            {value && (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="inline"
                >
                  {`Search results for: `}
                </Typography>
                <Typography variant="body2" display="inline">
                  {value.label} ({value.value.join(", ")})
                </Typography>
              </Grid>
            )}
            {searchResults.map(renderResult)}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Search;
