import React, { useState } from "react";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { useApi } from "../services/external-api";
import { useAuth0 } from "../services/react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const Prices = () => {
  const classes = useStyles();
  const [prices, isLoading, , refetch] = useApi("/prices", []);
  const [changes, setChange] = useState([]);
  const { getTokenSilently } = useAuth0();
  const handleChange = (price) => {
    const hasItem = !!changes.filter((val) => val.id === price.id).length;

    if (!hasItem) {
      changes.push(price);
    }

    setChange(
      changes.map((change) => {
        if (price.id === change.id) {
          return {
            ...change,
            ...price,
          };
        }

        return change;
      })
    );
  };
  const updateChanges = async () => {
    const token = await getTokenSilently();
    await fetch(`${process.env.REACT_APP_GALLERY_API_URL}/prices`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(changes),
    });
    setChange([]);
    refetch();
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const renderPrice = (price) => {
    return (
      <div key={price.id}>
        <Typography variant="h4">{price.size}</Typography>
        <TextField
          required
          id={`price-${price.id}`}
          label="Price"
          defaultValue={price.price}
          variant="outlined"
          onChange={(event) =>
            handleChange({ ...price, price: event.target.value })
          }
        />
        <TextField
          required
          id={`add-to-cart-type-${price.id}`}
          label="AddToCart Type"
          defaultValue={price.add_to_cart_type}
          variant="outlined"
          onChange={(event) =>
            handleChange({ ...price, add_to_cart_type: event.target.value })
          }
        />
      </div>
    );
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form className={classes.root} noValidate autoComplete="off">
            {prices.map(renderPrice)}
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={changes.length === 0}
                onClick={updateChanges}
              >
                Update
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Prices;
