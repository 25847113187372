import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./services/react-auth0-spa";
import history from "./utils/history";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// TODO: https://auth0.com/blog/complete-guide-to-react-user-authentication/
ReactDOM.render(
  <Auth0Provider
    domain="kinderkiez.eu.auth0.com"
    client_id="F3hd2QVgBLD2doiL8nqlwuM25ofEL39r"
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience="http://gallery.kinderkiez.net"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
