import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Paper,
  Box,
  Typography,
  Chip,
  TextField,
  Button,
} from "@material-ui/core";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { useApi } from "../services/external-api";

const useStyles = makeStyles({
  image: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
  },
});

function EditForm() {
  return (
    <form noValidate autoComplete="off">
      <TextField id="title" label="Title" variant="outlined" size="small" />
      <Button variant="contained" color="primary">
        Update
      </Button>
    </form>
  );
}

function EditGallery() {
  const params = useParams();
  const [gallery, isLoading] = useApi(`/galleries/${params.id}`, []);
  const classes = useStyles();

  if (isLoading || !gallery.id) {
    return <div>Loading ...</div>;
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper square>
            <Box p={2}>
              <Typography variant="h6">Preview Image</Typography>
              <img
                src={`//kindekiez.imgix.net/${gallery.image.key}?w=400`}
                alt={gallery.id}
                className={classes.image}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper square>
            <Box p={2}>
              <Typography variant="h6">Geocoding</Typography>
              <SyntaxHighlighter language="javascript" style={docco}>
                {JSON.stringify(gallery.location, null, 2)}
              </SyntaxHighlighter>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper square>
            <Box p={2}>
              <Typography variant="h6">Base Data</Typography>
              <Box>
                Latitude:{" "}
                {gallery.original_geojson.properties.viewport.latitude}
              </Box>
              <Box>
                Longitude:{" "}
                {gallery.original_geojson.properties.viewport.longitude}
              </Box>
              <Box>Format: {gallery.format}</Box>
              <Typography variant="h6">Curated Content</Typography>
              <Box>
                {gallery.curated_content > 0 ? (
                  <Chip label="Yes" color="primary" />
                ) : (
                  <Chip label="No" />
                )}
              </Box>
              <Typography variant="h6">Update Properties</Typography>
              <Box>
                <EditForm />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EditGallery;
