import {useState, useEffect} from "react";
import { useAuth0 } from "./react-auth0-spa";

export const callApi = async (path, token, method = undefined) => {
  if (!method) {
    method = "GET";
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GALLERY_API_URL}${path}`,
      {
        method,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export function useApi(path, initialData, method = undefined) {
  const { getTokenSilently } = useAuth0();
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!method) {
    method = "GET";
  }

  const fetchData = async () => {
    setError(null);
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_GALLERY_API_URL}${path}`,
        {
          method,
          headers: {
            Authorization: `Bearer ${await getTokenSilently()}`
          }
        }
      );
      setData(await response.json());
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, isLoading, error, fetchData];
}
