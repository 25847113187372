import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Chip } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

import { useApi } from "../services/external-api";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Dashboard = () => {
  const [galleries, isLoading] = useApi("/galleries", []);
  const classes = useStyles();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Preview</TableCell>
                  <TableCell align="right">ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Loaction</TableCell>
                  <TableCell align="right">Format</TableCell>
                  <TableCell align="right">Curated Content</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {galleries.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.image && (
                        <img
                          src={`//kindekiez.imgix.net/${row.image.key}?w=200`}
                          alt={row.id}
                        />
                      )}
                      {!row.image && (
                        <Link to={`/preview-image/${row.id}`}>
                          No image attached, fix it.
                        </Link>
                      )}
                    </TableCell>
                    <TableCell align="right">{row.id}</TableCell>
                    <TableCell align="right">{row.title}</TableCell>
                    <TableCell align="right">
                      {row.location && row.location.display_name}
                    </TableCell>
                    <TableCell align="right">{row.format}</TableCell>
                    <TableCell align="right">
                      {row.curated_content > 0 ? (
                        <Chip label="Yes" color="primary" />
                      ) : (
                        <Chip label="No" />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/galleries/${row.id}`}>Edit</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
