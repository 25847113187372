export const styleWithDynamicLayers = (style) => (
  {
    ...style,
    sources: {
      ...style.sources,
      placedIcons: {
        type: "geojson",
        generateId: true,
        data: {
          type: "FeatureCollection",
          features: []
        }
      },
      activeIcon: {
        type: "geojson",
        generateId: true,
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: []
          }
        }
      }
    },
    layers: [
      ...style.layers,
      {
        id: "placedIcons",
        type: "symbol",
        source: "placedIcons",
        layout: {
          "icon-image": ["get", "icon"],
          "icon-anchor": "bottom",
          "icon-size": ["coalesce", ["get", "size"], 1],
          "icon-rotate": ["coalesce", ["get", "rotation"], 0],
          "icon-allow-overlap": true,
          "symbol-sort-key": ["get", "priority"],
          "symbol-z-order": "auto"
        },
        paint: {
          "icon-opacity": [
            "case",
            ["==", ["feature-state", "active"], true],
            0,
            1
          ],
          "icon-opacity-transition": {
            duration: 0
          }
        }
      },
      {
        id: "activeIcon",
        type: "symbol",
        source: "activeIcon",
        layout: {
          "icon-image": ["get", "icon"],
          "icon-anchor": "bottom",
          "icon-size": ["coalesce", ["get", "size"], 1],
          "icon-rotate": ["coalesce", ["get", "rotation"], 0],
          "icon-allow-overlap": true,
          "icon-ignore-placement": true
        },
        paint: {
          "icon-opacity-transition": {
            duration: 0
          }
        }
      }
    ]
  }
);